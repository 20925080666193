import Text from "components/dist/atoms/Text";
import { format } from 'date-fns';
import { useRouter } from "next/router";
import { useListener } from "polyrhythm-react";
import { createContext, FC, MouseEvent, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { AppUserDTO2, FormElementV2ResponseDto, KnowledgeBase, LoanViewType, PackageInfoSharingRequestDto, PackageInfoSharingResponseDto, PriorityType, Role, SharePermissionType } from "src/backend";
import { ConfirmationDialog } from "src/components/common/v2/confirmation-dialog";
import { FormElementCamCapture } from "src/components/form-elements/form-element-cam-capture";
import { FormElementDialog } from "src/components/form-elements/form-element-dialog";
import { DeleteQuestionConfirmDialog } from "src/components/form-elements/form-element-dropzone/delete-question-confirm-dialog";
import { MergeFormElementConfirmDialog } from "src/components/form-elements/form-element-dropzone/merge-form-element-confirm-dialog";
import { FormElementFormDialog } from 'src/components/form-elements/form-element-list/form-element-form-dialog.component';
import { ResetFileFormElementDialog } from "src/components/form-elements/reset-file-formelement-dialog";
import { ZipDadActionDialog } from "src/components/form-elements/zip-dad-action-dialog";
import { ZipPasswordDialog } from "src/components/form-elements/zip-password-dialog";
import { UsersSelectDialog } from "src/components/v2/users-select-dialog";
import { DEFAULT_FROM_ELEMENT_DISPLAY_ORDER, PINNED_FROM_ELEMENT_DISPLAY_ORDER, QUERY_PARAM_FORM_ELEMENT_ID, QUERY_PARAM_FORM_ELEMENT_IDS, Type, UploadFileStrategy } from "src/constants/form-element";
import { ShoeBoxQueryParam } from "src/constants/shoebox";
import { StorageType } from "src/constants/template";
import { LOAN_TABS, LoanSidebar } from "src/constants/ui";
import { useUserLoanViewType } from "src/hooks/loans/use-user-loan-view-type";
import { useGetElements } from 'src/hooks/use-get-elements';
import { useLenderEmployees } from 'src/hooks/use-lender-employees';
import { useSearchUsers } from 'src/hooks/use-search-users';
import { useUser } from "src/hooks/use-user";
import { useCreateSharedInfoElementMutation, useDeleteSharedInfoElementMutation } from "src/services/packageApi";
import { answerFileFormElement, approveFormElements, deleteFormElementAnswer, deleteFormElements, getLoanFormElements, rejectFormElements, resetFormElementAnswer, setDocumentPreviewPinned, unApproveFormElement, updateFormElements } from "src/slices/form-element";
import { createLoanShoeBoxItem } from "src/slices/shoebox";
import { setExpandedAccordionUserId } from "src/slices/task";
import { setOpenLoanSidebar } from "src/slices/ui";
import { useDispatch } from "src/store";
import { FormElementV2ResponseDtoExtended } from 'src/types/formelement';
import { Loan } from "src/types/loan";
import { createUrlSlug } from "src/utils";
import { getElementActionsToastMessage } from "src/utils/form-element/get-element-actions-toast-message";
import { getChildrenIds } from "src/utils/form-elements";
import { toast } from "src/utils/toast";
import { isRoleABorrower } from 'src/utils/user/is-role-a-borrower';
import { isRoleALead } from 'src/utils/user/is-role-a-lead';
import { isRoleAManager } from 'src/utils/user/is-role-a-manager';

import { FormElementContextReducer } from "./form-element-context.reducer";
import { OnDropFileType } from './form-element-context.types';

const MULTI_SELECT_INITIAL_STATE = { formElements: [] };

const initialSettings = {
    deleteDialogOpen: null,
    // replaceDialogOpen: null,
    onCloseDeleteDialog: () => { },
    // onCloseReplaceDialog: () => { },
    onConfirmReplaceDialog: () => { },
    onShare: () => new Promise<void>(() => { }),
    onUnShare: () => new Promise<void>(() => { }),
    onApproveMultiple: () => { },
    onUnApproveMultiple: () => { },
    onSetShoeBoxFiles: () => { },
    onOpenConfirmReplaceDialog: () => { },
    onOpenFormElementDialog: () => { },
    onToggleMultiSelect: () => { },
    onConfirmDelete: () => { },
    onSelectFormElement: () => { },
    onConfirmDeleteDialog: () => { },
    onUnselectFormElement: () => { },
    onPinToTopClick: () => { },
    onUpdatePriority: () => new Promise<void>(() => { }),
    onMultiSelect: () => { },
    onDirtyPreviewFile: () => { },
    onResetMultiSelect: () => { },
    onSetMultiSelect: () => { },
    onMoveFormElements: () => new Promise<void>(() => { }),
    onApproveFormElement: () => { },
    onSetEditFileRequestId: () => { },
    onSetAssignElements: () => { },
    onRejectFormElement: () => { },
    // onOpenSideBySideDialog: () => { },
    onOpenResetFormElementFromTemplate: () => { },
    onDocumentSelected: () => { },
    onAssignClick: () => { },
    onOpenCamScan: () => { },
    onToggleVisibility: () => { },
    onCloseConsolidatedViewDialog: () => { },
    multiSelect: MULTI_SELECT_INITIAL_STATE,
    deletingId: null,
    activeFolderId: null,
    onClearNeedsList: () => { },
    companyLenders: [],
    companyBorrowers: [],
    onSetNeedsListElementsIds: () => { },
    isConfirmAppendAlertOpen: false,
    needsListElements: [], hasPreviousMessageElements: false,
    dirtyPreviewFile: null,
    deleteFormElementId: null,
    activeFormElementFolder: null,
    editFileRequestId: null,
};
interface SettingsProviderProps {
    children?: ReactNode;
    loan: Loan;
}

interface FormElementContextValue {
    deleteDialogOpen: boolean;
    activeFolderId: string;
    isConfirmAppendAlertOpen: boolean;
    onCloseDeleteDialog: () => void;
    onToggleVisibility: (formElement: FormElementV2ResponseDto) => void;
    onSetEditFileRequestId: (requestId: string) => void;
    // onCloseReplaceDialog: () => void;
    onSetAssignElements: (formElements: FormElementV2ResponseDtoExtended[]) => void;
    onSetShoeBoxFiles: (files: File[]) => void;
    onOpenCamScan: (formElement: FormElementV2ResponseDto) => void;
    onApproveMultiple: () => void;
    onSetMultiSelect: (formElements: FormElementV2ResponseDto[]) => void;
    onUnApproveMultiple: () => void;
    onMoveFormElements: (formElements: FormElementV2ResponseDto[], targetFormElement: FormElementV2ResponseDto) => Promise<void>;
    onResetMultiSelect: () => void;
    onShare: (userIds: string[], formElement?: FormElementV2ResponseDto, noToast?: boolean) => Promise<void>;
    onUnShare: (userIds: string[]) => Promise<void>;
    // onConfirmReplaceDialog: (action: UploadFileStrategy, files: File[], items: DroppedDocument[]) => void;
    onToggleMultiSelect: () => void;
    onConfirmDelete: (type: 'ANSWER' | 'QUESTION' | 'ALL') => void;
    // onOpenConfirmReplaceDialog: (func: (action: UploadFileStrategy, files: File[], items: DroppedDocument[]) => void, action: UploadFileStrategy, files: File[], items: DroppedDocument[], formElement: FormElementV2ResponseDto) => void;
    onSelectFormElement: (formElement: FormElementV2ResponseDto, event: MouseEvent<HTMLDivElement>) => void;
    onUnselectFormElement: (formElement: FormElementV2ResponseDto, event: MouseEvent<HTMLDivElement>) => void;
    onMultiSelect: (formElement: FormElementV2ResponseDto) => void;
    // onOpenSideBySideDialog: (formElement: FormElementV2ResponseDto) => void;
    onOpenFormElementDialog: (formElement: Partial<FormElementV2ResponseDto>) => void;
    onConfirmDeleteDialog: (formElement: FormElementV2ResponseDto, type: 'ANSWER' | 'QUESTION') => void;
    onPinToTopClick: (formElementId: string, isPinned: boolean) => void;
    onApproveFormElement: (formElement: FormElementV2ResponseDto) => void;
    onRejectFormElement: (formElement: FormElementV2ResponseDto) => void;
    onDocumentSelected: (formElementId: string, documentId: string) => void;
    onOpenResetFormElementFromTemplate: (templateId: string, pickCallback: () => void) => void;
    onUpdatePriority: (args: {
        formElements: FormElementV2ResponseDto[];
        priority: PriorityType;
    }) => Promise<void>;
    onDirtyPreviewFile: (file: File) => void;
    onSetNeedsListElementsIds: (formElementIds: string[]) => void;
    onClearNeedsList: () => void;
    onAssignClick: (event: any, formElement: FormElementV2ResponseDto) => void;
    deletingId: string;
    multiSelect: {
        formElements: FormElementV2ResponseDtoExtended[];
    };
    companyLenders: AppUserDTO2[];
    editFileRequestId: string,
    companyBorrowers: AppUserDTO2[];
    dirtyPreviewFile: File | null;
    disableRouting?: boolean;
    hasPreviousMessageElements: boolean;
    deleteFormElementId?: string;
    needsListElements: FormElementV2ResponseDtoExtended[];
    activeFormElementFolder: FormElementV2ResponseDto;
}

export const FormElementContext = createContext<FormElementContextValue>(initialSettings);

export const FormElementProvider: FC<SettingsProviderProps> = (props) => {
    const [state, reducerDispatch] = useReducer(FormElementContextReducer.reducer, FormElementContextReducer.initialState);
    const [multiSelect, setMultiSelect] = useState<{ formElements: FormElementV2ResponseDtoExtended[] }>(MULTI_SELECT_INITIAL_STATE);
    // TODO all this state should be moved to react reducer
    const [isScreenshotDialogOpen, setIsScreenshotDialogOpen] = useState<FormElementV2ResponseDtoExtended | null>();
    const [formDialogOpen, setFormDialogOpen] = useState<boolean>(false);
    const [mergeConfirmDialogOpen, setMergeConfirmDialogOpen] = useState<boolean>(false);
    const [mergeAcceptCallback, setMergeAcceptCallback] = useState<{ callback: () => void }>({ callback: () => null });
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<'ANSWER' | 'QUESTION'>(initialSettings.deleteDialogOpen);
    // const [replaceDialogOpen, setReplaceDialogOpen] = useState<{ uploadStrategy: UploadFileStrategy, files: File[], items: DroppedDocument[], title: string, destinationDocumentName: string }>(initialSettings.replaceDialogOpen);
    const [formElement, setFormElement] = useState<Partial<FormElementV2ResponseDto>>(null);
    // const [confirmReplaceDialogCallback, setConfirmReplaceDialogCallback] = useState<{ callback: (action: UploadFileStrategy, files: File[], items: DroppedDocument[]) => void }>(null);
    const [showBoxFiles, setShowBoxFiles] = useState<File[]>([]);
    const { user: currentUser, isLender, isBorrower } = useUser();
    const [createSharedInfoElement] = useCreateSharedInfoElementMutation();
    const [deleteSharedInfoElement] = useDeleteSharedInfoElementMutation();
    const isUserALender = isLender
    const router = useRouter();
    // loan form elements
    const elementsState = useGetElements({ loanId: props.loan?.id });
    const { employees } = useLenderEmployees({
        lenderId: props.loan?.lender.id,
        skip: !isLender || !props.loan?.id
    });
    const { borrowers } = useSearchUsers({
        skip: isBorrower,
    });

    const { loanViewType } = useUserLoanViewType();
    // get root form element
    const rootFormElement = elementsState.list.find(formElement => !formElement.parentId);
    // current form element
    const currentFormElementId = router.query.formElementId ? String(router.query.formElementId) : rootFormElement?.id;
    const queryFormElement = elementsState.list.find(formElement => formElement.id === currentFormElementId);
    const activeFolderId = queryFormElement?.storageType === StorageType.FOLDER ? queryFormElement.id : (queryFormElement?.parentId ?? rootFormElement?.id);
    const activeFormElementFolder = elementsState.list.find(formElement => formElement.id === activeFolderId);

    // other hooks
    const dispatch = useDispatch();

    const onSelect = useCallback((formElement: FormElementV2ResponseDto, event: MouseEvent<HTMLElement>) => {
        if (!!router.query[ShoeBoxQueryParam.documentId]) {
            router.push({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    tab: LOAN_TABS.PACKAGE,
                    [ShoeBoxQueryParam.documentId]: '',
                    [ShoeBoxQueryParam.documentName]: '',
                    [QUERY_PARAM_FORM_ELEMENT_ID]: formElement.id
                }
            });
        }
    }, [router]);

    const cleanupCallbacks = () => {
        setFormElement(null);
    }

    const resetMultiSelect = useCallback(() => {
        setMultiSelect(MULTI_SELECT_INITIAL_STATE);
        // remove highlighted elements if it exits
        if (!!router.query[QUERY_PARAM_FORM_ELEMENT_IDS]) {
            router.replace({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    [QUERY_PARAM_FORM_ELEMENT_IDS]: undefined
                }
            });
        }
    }, [router])

    const handleResetMultiSelect = useCallback(() => {
        setMultiSelect(MULTI_SELECT_INITIAL_STATE)
        // clear all highlighted elements
        router.replace({
            pathname: router.pathname,
            query: {
                ...router.query,
                [QUERY_PARAM_FORM_ELEMENT_IDS]: undefined
            }
        });
    }, [router]);

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(null);
    }

    const handleMultiDelete = useCallback(async (type: 'ANSWER' | 'QUESTION' | 'ALL' = 'ALL') => {
        // if type is ALL, delete all form elements
        // if type is QUESTION, delete only file form elements
        // if type is ANSWER, delete only answered form elements
        const formElementsToDelete = multiSelect.formElements.filter(formElement => {
            if (formElement.knowledgeBase === KnowledgeBase.VIRTUAL_FOLDER) {
                return false;
            }
            if (type === 'ANSWER') {
                return !!formElement.answer && (isUserALender ||
                    (formElement.answer?.document?.createdByUser?.username === currentUser.username && !formElement.approved));
            }
            if (type === 'QUESTION') {
                return formElement.storageType === Type.FILE && (isUserALender || (formElement.createdBy === currentUser.username && !formElement.approved));
            }
            return true;
        });
        reducerDispatch({
            type: FormElementContextReducer.action.setDeletingId,
            payload: 'multiple'
        });
        // if type is answer we loop throw all form elements and delete answers
        if (type === 'ANSWER') {
            const promises = formElementsToDelete.map(formElement => dispatch(deleteFormElementAnswer({ formElement })));
            await Promise.all(promises);
            toast({
                content: `${formElementsToDelete.length} items answers deleted successfully`,
                type: 'success',
                position: 'bottom-right'
            })
        } else if (formElementsToDelete.length > 0) {
            // otherwise we delete filtered form elements
            await dispatch(deleteFormElements({
                multiSelect: true,
                formElementIds: formElementsToDelete.map(({ id }) => id),
                loanId: props.loan.id
            }));
            toast({
                content: `${formElementsToDelete.length} items deleted successfully`,
                type: 'success',
                position: 'bottom-right'
            })
        }
        const virtualFolderTitles = multiSelect.formElements.filter(({ knowledgeBase }) => knowledgeBase === KnowledgeBase.VIRTUAL_FOLDER).map(({ title }) => title);
        if (virtualFolderTitles.length) {
            toast({
                content: `System Folders ${virtualFolderTitles.join(', ')} cannot be deleted.`,
                type: 'error',
                position: 'bottom-right'
            })
        }
        await dispatch(getLoanFormElements(props.loan.id, true));
        reducerDispatch({
            type: FormElementContextReducer.action.setDeletingId,
            payload: null
        });
        setDeleteDialogOpen(null);
        resetMultiSelect();
    }, [multiSelect.formElements, dispatch, props.loan?.id, resetMultiSelect, isUserALender, currentUser.username]);

    const handleOpenDeleteDialog = useCallback((formElementToDelete: Partial<FormElementV2ResponseDto>, type: 'ANSWER' | 'QUESTION') => {
        resetMultiSelect();
        setDeleteDialogOpen(type);
        setFormElement(formElementToDelete);
    }, [resetMultiSelect]);

    const handleConfirmDeleteDialog = useCallback(async (type: 'ANSWER' | 'QUESTION' | 'ALL') => {
        if (multiSelect.formElements.length > 0) {
            handleMultiDelete(type);
            return;
        }
        if (!formElement) {
            return;
        }
        reducerDispatch({
            type: FormElementContextReducer.action.setDeletingId,
            payload: formElement.id
        });
        try {
            if (type === 'QUESTION') {
                const isSuccess = await dispatch(deleteFormElements({
                    multiSelect: false,
                    formElementIds: [formElement.id],
                    loanId: props.loan.id
                }));
                if (isSuccess) {
                    if (formElement.storageType === Type.FILE) {
                        router.replace({
                            pathname: router.pathname,
                            query: {
                                ...router.query,
                                [QUERY_PARAM_FORM_ELEMENT_ID]: formElement.parentId
                            }
                        });
                    }
                    toast({
                        content: `${formElement.title} deleted successfully`,
                        type: 'success',
                        position: 'bottom-right'
                    })
                }
            } else if (type === 'ANSWER') {
                const isSuccess = await dispatch(deleteFormElementAnswer({ formElement }));
                if (isSuccess) {
                    toast({
                        content: `${formElement.title} answer deleted successfully`,
                        type: 'success',
                    })
                }
            }
        } catch (error) {
            toast({
                content: 'Unable to delete',
                type: 'error',
            })
        }
        await dispatch(getLoanFormElements(props.loan.id, true));
        reducerDispatch({
            type: FormElementContextReducer.action.setDeletingId,
            payload: null
        });
        setDeleteDialogOpen(null);
        cleanupCallbacks();
    }, [multiSelect.formElements.length, formElement, dispatch, props.loan?.id, handleMultiDelete, router]);

    const handleCloseAssignUsersPopOver = () => {
        setFormElement(null);
        reducerDispatch({
            type: FormElementContextReducer.action.setAssignIsOpen,
            payload: false
        });
    }

    const handleToggleAssignSectionToUser = useCallback((_event: any, formElementToAssign: FormElementV2ResponseDtoExtended) => {
        if (!formElementToAssign) {
            handleCloseAssignUsersPopOver();
        } else {
            setFormElement(formElementToAssign);
            reducerDispatch({
                type: FormElementContextReducer.action.setAssignIsOpen,
                payload: true
            });
            reducerDispatch({
                type: FormElementContextReducer.action.setAssignElements,
                payload: [formElementToAssign]
            });
        }
    }, []);


    const handleMultiSelect = useCallback((formElementToSelect: FormElementV2ResponseDto) => {
        const childrenFormElements = [];
        if ([formElementToSelect.id, ...formElementToSelect.childrenIds].includes(String(router.query[QUERY_PARAM_FORM_ELEMENT_ID])) || !formElementToSelect.parentId) {
            childrenFormElements.push(...elementsState.list.filter(({ id }) => formElementToSelect.childrenIds.includes(id)));
        }

        // if the form element is already selected, remove it from the list
        if (multiSelect.formElements.some(({ id }) => id === formElementToSelect.id)) {
            setMultiSelect(prevState => ({
                formElements: [...new Set(prevState.formElements.filter(({ id }) => id !== formElementToSelect.id))]
            }));
            // if the form element is already selected, remove it from the list
        } else if (multiSelect.formElements.some(({ id }) => formElementToSelect.childrenIds.includes(id))) {
            setMultiSelect(prevState => ({
                formElements: [...new Set(prevState.formElements.filter(({ id }) => !formElementToSelect.childrenIds.includes(id)))]
            }));
        } else {
            // if the form element is not selected, add it to the list
            setMultiSelect(prevState => ({
                formElements: [...new Set([...prevState.formElements, ...childrenFormElements, formElementToSelect])]
            }));
        }
        // if element is highlighted, remove it from the query
        if (Array.isArray(router.query[QUERY_PARAM_FORM_ELEMENT_IDS]) && router.query[QUERY_PARAM_FORM_ELEMENT_IDS].includes(formElementToSelect.id)) {
            router.replace({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    [QUERY_PARAM_FORM_ELEMENT_IDS]: router.query[QUERY_PARAM_FORM_ELEMENT_IDS].filter(id => id !== formElementToSelect.id)
                }
            });
        }
    }, [router, multiSelect.formElements, elementsState.list]);

    const handleSelectFormElement = useCallback((formElementToSelect: FormElementV2ResponseDto, event: MouseEvent<HTMLDivElement>) => {
        onSelect(formElementToSelect, event);
    }, [onSelect]);

    const handleUnselectFormElement = useCallback((formElementToUnselect: FormElementV2ResponseDto, event: MouseEvent<HTMLDivElement>) => {
        dispatch(setDocumentPreviewPinned(false));
        if (router.query[ShoeBoxQueryParam.documentId]) {
            onSelect(formElementToUnselect, event);
        } else {
            router.replace({
                pathname: router.pathname,
                query: {
                    ...router.query,
                    tab: LOAN_TABS.PACKAGE,
                    [QUERY_PARAM_FORM_ELEMENT_ID]: formElementToUnselect.parentId
                }
            });
        }
    }, [dispatch, router, onSelect]);

    const handleCloseFormElementDialog = () => {
        setFormDialogOpen(false);
        setFormElement(null);
    }

    const handleDirtyPreviewFile = useCallback((file: File) => {
        reducerDispatch({
            type: FormElementContextReducer.action.setDirtyPreviewFile,
            payload: file
        });
    }, []);

    const handleOpenFormElementDialog = useCallback((formElementToEdit: Partial<FormElementV2ResponseDto>) => {
        setFormDialogOpen(true);
        const isSimpleView = loanViewType === LoanViewType.CONSOLIDATED_LENDER;
        setFormElement({
            ...formElementToEdit,
            ...(!isSimpleView ? {
                parentId: formElementToEdit.parentId
            } : {
                parentId: null
            })
        });
    }, [loanViewType]);

    const handleApproveMultiple = useCallback(() => {
        // get only selected form elements with answers
        const cleanFormElementsWithAnswers = multiSelect.formElements.filter(({ answer, isVirusClean }) => !!answer && isVirusClean);
        const infectedElements = multiSelect.formElements.filter(({ answer, isVirusClean }) => !isVirusClean && !!answer);
        dispatch(approveFormElements({
            multiSelect: true,
            formElements: cleanFormElementsWithAnswers,
            loanId: props.loan.id,
            userId: currentUser.id
        }));
        if (infectedElements.length) {
            toast({
                type: 'success',
                content: getElementActionsToastMessage(cleanFormElementsWithAnswers, infectedElements, 'approved'),
            })
        }
        resetMultiSelect();
    }, [multiSelect.formElements, dispatch, props.loan?.id, currentUser.id, resetMultiSelect]);

    const handleUnApproveMultiple = useCallback(() => {
        // get only selected form elements with answers
        const formElementsWithAnswers = multiSelect.formElements.filter(({ answer }) => !!answer);
        dispatch(unApproveFormElement({
            multiSelect: true,
            formElements: formElementsWithAnswers,
            loanId: props.loan.id,
            userId: currentUser.id
        }));
        resetMultiSelect();
    }, [multiSelect.formElements, dispatch, props.loan?.id, currentUser.id, resetMultiSelect]);

    const handleToggleMultiSelect = () => {
        setMultiSelect(prevState => ({
            formElements: []
        }));
    }

    const handleApproveAnswer = useCallback((formElement: FormElementV2ResponseDto) => {
        dispatch(approveFormElements({
            multiSelect: false,
            formElements: [formElement],
            loanId: formElement.loanId,
            userId: currentUser.id
        }));
        if (formElement.canHaveExpiration && !formElement.expireDate) {
            setFormDialogOpen(true);
            setFormElement({
                ...formElement,
                approved: true
            });
        }
    }, [currentUser.id, dispatch]);

    const handleRejectAnswer = useCallback((formElement: FormElementV2ResponseDto) => {
        dispatch(rejectFormElements({
            multiSelect: false,
            formElements: [formElement],
            loanId: formElement.loanId,
            userId: currentUser.id
        }));
    }, [currentUser.id, dispatch]);

    const handleCopyFormTemplateClick = (templateId: string) => {
        dispatch(resetFormElementAnswer({ loanId: props.loan.id, formElementId: templateId }));

    }

    const handleCancelResetFormElement = () => {
        reducerDispatch({
            type: FormElementContextReducer.action.setTemplateId,
            payload: null
        });
    }

    const handleOpenResetFormElementFromTemplate = (templateId: string, func: () => void) => {
        reducerDispatch({
            type: FormElementContextReducer.action.setTemplateId,
            payload: templateId
        });
        // setConfirmReplaceDialogCallback({ callback: func });
    }

    const handleResetFilePickerClick = () => {
        reducerDispatch({
            type: FormElementContextReducer.action.setTemplateId,
            payload: null
        });
        // confirmReplaceDialogCallback?.callback?.(UploadFileStrategy.Replace, [], []);
    }


    const handlePinToTopClick = useCallback((formElementId: string, isPinned: boolean) => {
        dispatch(
            updateFormElements({
                multiSelect: false,
                loanId: props.loan.id,
                formElements: [
                    {
                        id: formElementId,
                        displayOrder: isPinned ? DEFAULT_FROM_ELEMENT_DISPLAY_ORDER : PINNED_FROM_ELEMENT_DISPLAY_ORDER,
                        loanId: props.loan.id,
                    }
                ]
            }))
    }, [dispatch, props.loan?.id]);

    const handleDocumentSelected = useCallback((formElementId: string, answerId: string) => {
        dispatch(answerFileFormElement({
            loanId: props.loan.id,
            formElementId,
            answerId,
            documentId: null,
            submit: null,
            isMerged: false
        }))
    }, [dispatch, props.loan?.id]);

    const handleCloseScreenshotDialog = () => {
        setIsScreenshotDialogOpen(null);
    }

    const handleDroppedFiles = (args: OnDropFileType) => {
        if (args.dropType !== "DEFAULT") {
            reducerDispatch({
                type: FormElementContextReducer.action.setConsolidatedView,
                payload: args
            });
        }
    }

    const handleOpenCamScanDialog = (formElement: FormElementV2ResponseDtoExtended) => {
        setIsScreenshotDialogOpen(formElement);
    }

    const onSetNeedsListElementsIds = (ids: string[]) => {
        reducerDispatch({
            type: FormElementContextReducer.action.setNeedsListElementsIds,
            payload: ids
        });
    }

    const onClearNeedsList = () => {
        reducerDispatch({
            type: FormElementContextReducer.action.setNeedsListElementsIds,
            payload: []
        });
    }

    const handleUpdatePriority = useCallback(async (args: {
        formElements: FormElementV2ResponseDto[];
        priority: PriorityType;
    }) => {
        await dispatch(updateFormElements({
            multiSelect: false,
            formElements: args.formElements.map(formElement => ({ id: formElement.id, priorityType: args.priority, loanId: formElement.loanId })),
            loanId: props.loan.id
        }));
    }, [dispatch, props.loan?.id]);

    const handleConfirmUploadFilesToShoeBox = async (files: File[]) => {
        // close dialog
        setShowBoxFiles([]);
        // open showbox sidebar
        dispatch(setExpandedAccordionUserId(currentUser.id));
        dispatch(setOpenLoanSidebar(LoanSidebar.TASKS_LIST_SHOE_BOX));
        // start uploading files
        for (const file of files) {
            await dispatch(createLoanShoeBoxItem({
                loanId: props.loan.id,
                file,
                userId: currentUser.id
            }))
        }
        toast({
            type: 'success',
            content: `${files.length} items added to your shoebox on Loan ${props.loan.shortCode}`
        })
    }

    const handleMoveFormElements = useCallback(async (elementsToMove: FormElementV2ResponseDto[], targetFormElement: FormElementV2ResponseDto) => {
        // filter out virtual folders first
        const nonVirtualFolderElements = elementsToMove.filter(({ knowledgeBase }) => knowledgeBase !== KnowledgeBase.VIRTUAL_FOLDER);
        // get total of elements
        const totalElements = nonVirtualFolderElements.length;
        // if we have elements to move
        if (totalElements > 0) {
            const [firstElement] = nonVirtualFolderElements;
            await dispatch(updateFormElements({
                multiSelect: true,
                formElements: nonVirtualFolderElements.map(formElement => ({
                    id: formElement.id,
                    parentId: targetFormElement.id,
                    loanId: props.loan.id
                })),
                loanId: props.loan.id
            }));

            toast({
                content: `${totalElements > 1 ? `${totalElements} items` : firstElement.title} moved to ${targetFormElement.title}`,
                type: 'success',
            })
        }
        resetMultiSelect();
    }, [dispatch, props.loan?.id]);

    const handleUnshareFormElement = useCallback(async (usersIds: string[], formElement?: FormElementV2ResponseDtoExtended) => {
        // loop throw multi select form elements and get all shares
        const formElementsToUnShare = [
            ...multiSelect.formElements.filter(element => element.id !== formElement?.id),
            ...(formElement?.id
                ? [formElement]
                : [])];

        const sharedInfo = formElementsToUnShare.reduce((acc, formElement) => {
            return [...acc, ...formElement.sharedInfo];
        }, [] as PackageInfoSharingResponseDto[]);

        const shares = sharedInfo
            // only delete the shares that are shared with the users that we want to unshare
            .filter(share => usersIds.includes(share.sharedWithUser.id))
            .map(share => ({
                id: share.id,
                infoId: share.info.id,
                loanId: share.loanId,
                permissions: null,
                sharedByUserId: null,
                sharedWithUserId: null,
            }));
        await deleteSharedInfoElement({ shares }).unwrap();
        dispatch(getLoanFormElements(props.loan.id));
        toast({
            content: 'Users removed successfully',
            type: 'success',
        })
        reducerDispatch({
            type: FormElementContextReducer.action.setAssignElements,
            payload: []
        });
        resetMultiSelect();
    }, [deleteSharedInfoElement, dispatch, multiSelect.formElements, props.loan?.id]);

    const handleShareFormElement = useCallback(async (userIds: string[], formElement?: FormElementV2ResponseDto, noToast?: boolean) => {
        const shares: PackageInfoSharingRequestDto[] = [];
        // merge multiSelect.formElements
        const formElementsToShare = [
            ...multiSelect.formElements.filter(element => element.id !== formElement?.id),
            ...(formElement?.id
                ? [formElement]
                : [])];

        // create a share for each user and multi select form elements
        const loanUserCanAccept = props.loan.loanRoles.reduce((acc, loanRole) => {
            return {
                ...acc,
                [loanRole.user.id]: loanRole.canAcceptFiles
            }
        }, {} as Record<string, boolean>);

        const employeeRole = employees.reduce((acc, lender) => {
            return {
                ...acc,
                [lender.id]: lender.loggedCompanyRole
            }
        }, {} as Record<string, Role>);

        const loanUserRole = props.loan.loanRoles.reduce((acc, loanRole) => {
            return {
                ...acc,
                [loanRole.user.id]: loanRole.role
            }
        }, {} as Record<string, Role>);
        formElementsToShare.forEach(formElement => {
            userIds.forEach(userId => {
                const finalRole = loanUserRole[userId] || employeeRole[userId];
                const canAcceptFiles = (loanUserCanAccept[userId] || isRoleAManager(finalRole) || isRoleALead(finalRole)) && !isRoleABorrower(finalRole)

                shares.push({
                    id: null,
                    infoId: formElement.id,
                    loanId: props.loan.id,
                    permissions: [
                        SharePermissionType.VIEW,
                        ...(canAcceptFiles
                            ? [SharePermissionType.ACCEPT]
                            : [])
                    ],
                    sharedByUserId: currentUser.id,
                    sharedWithUserId: userId,
                });
            })
        });
        await createSharedInfoElement({ shares }).unwrap();
        await dispatch(getLoanFormElements(props.loan.id));
        if (!noToast) {
            toast({
                content: 'Users assigned successfully',
                type: 'success',
            })
        }
        resetMultiSelect();
        reducerDispatch({
            type: FormElementContextReducer.action.setAssignElements,
            payload: []
        });
    }, [createSharedInfoElement, currentUser.id, dispatch, employees, multiSelect.formElements, props.loan?.id, props.loan?.loanRoles, resetMultiSelect])

    const onSetMultiSelect = (formElements: FormElementV2ResponseDtoExtended[]) => {
        setMultiSelect({
            formElements
        });
    }

    const onSetAssignElements = (formElements: FormElementV2ResponseDtoExtended[]) => {
        reducerDispatch({
            type: FormElementContextReducer.action.setAssignElements,
            payload: formElements
        });
    }

    const onSetEditFileRequestId = (editFileRequestId: string) => {
        reducerDispatch({
            type: FormElementContextReducer.action.setEditFileRequestId,
            payload: editFileRequestId
        });
    }

    const onToggleVisibility = useCallback((formElement: FormElementV2ResponseDto) => {
        // if the form element is not a virtual folder, then we can toggle the visibility
        if (formElement.knowledgeBase !== KnowledgeBase.VIRTUAL_FOLDER) {
            dispatch(
                updateFormElements({
                    multiSelect: false,
                    formElements: [
                        {
                            id: formElement.id,
                            hidden: !formElement.hidden,
                            loanId: props.loan.id,
                        },
                    ],
                    loanId: props.loan.id,
                })
            );
        } else {
            // else if it's a virtual folder then we need to get all the children and their children recursively
            // and then toggle the visibility of all of them
            const childrenIds = getChildrenIds(
                formElement,
                elementsState.list
            );
            dispatch(
                updateFormElements({
                    multiSelect: false,
                    formElements: childrenIds.map((childId) => ({
                        id: childId,
                        hidden: !formElement.hidden,
                        loanId: props.loan.id,
                    })),
                    loanId: props.loan.id,
                })
            );
        }
    }, [dispatch, elementsState.list, props.loan?.id]);

    useListener('/elements/copy-move/highlight', (event) => {
        setMultiSelect({
            formElements: event.payload
        })
    })


    // whenever activeFolderId changes we should reset multi select
    // because we are changing folder

    useEffect(() => {
        resetMultiSelect();
    }, [activeFolderId]);

    const value = useMemo(() => ({
        onSetShoeBoxFiles: setShowBoxFiles,
        onSetMultiSelect,
        onSetEditFileRequestId,
        // onCloseReplaceDialog: handleCloseReplaceDialog,
        onCloseDeleteDialog: handleCloseDeleteDialog,
        // onConfirmReplaceDialog: handleConfirmReplaceDialog,
        // onOpenConfirmReplaceDialog: handleSetConfirmReplaceDialogCallback,
        onSelectFormElement: handleSelectFormElement,
        onUnselectFormElement: handleUnselectFormElement,
        onMoveFormElements: handleMoveFormElements,
        onConfirmDelete: handleConfirmDeleteDialog,
        onApproveMultiple: handleApproveMultiple,
        onUnApproveMultiple: handleUnApproveMultiple,
        onConfirmDeleteDialog: handleOpenDeleteDialog,
        onAssignClick: handleToggleAssignSectionToUser,
        onOpenFormElementDialog: handleOpenFormElementDialog,
        onDirtyPreviewFile: handleDirtyPreviewFile,
        onOpenResetFormElementFromTemplate: handleOpenResetFormElementFromTemplate,
        onMultiSelect: handleMultiSelect,
        onResetMultiSelect: handleResetMultiSelect,
        onToggleMultiSelect: handleToggleMultiSelect,
        onUpdatePriority: handleUpdatePriority,
        onOpenCamScan: handleOpenCamScanDialog,
        onPinToTopClick: handlePinToTopClick,
        onApproveFormElement: handleApproveAnswer,
        onRejectFormElement: handleRejectAnswer,
        onDocumentSelected: handleDocumentSelected,
        onSetNeedsListElementsIds,
        onClearNeedsList,
        onShare: handleShareFormElement,
        onUnShare: handleUnshareFormElement,
        onSetAssignElements,
        onToggleVisibility,
        deleteDialogOpen: !!deleteDialogOpen,
        activeFormElementFolder,
        dirtyPreviewFile: state.dirtyPreviewFile,
        deletingId: state.deletingId,
        multiSelect,
        editFileRequestId: state.editFileRequestId,
        deleteFormElementId: formElement?.id,
        companyLenders: employees,
        activeFolderId: activeFolderId,
        isConfirmAppendAlertOpen: state.isConfirmAppendAlertOpen,
        companyBorrowers: borrowers,
        needsListElements: elementsState.list.filter(({ id }) => state.needsListElementsIds.includes(id)),
        hasPreviousMessageElements: state.needsListElementsIds.length > 0,
    }), [handleSelectFormElement, handleUnselectFormElement, handleMoveFormElements, handleConfirmDeleteDialog, handleApproveMultiple, handleUnApproveMultiple, handleOpenDeleteDialog, handleToggleAssignSectionToUser, handleOpenFormElementDialog, handleDirtyPreviewFile, handleMultiSelect, handleResetMultiSelect, handleUpdatePriority, handlePinToTopClick, handleApproveAnswer, handleRejectAnswer, handleDocumentSelected, handleShareFormElement, handleUnshareFormElement, onToggleVisibility, deleteDialogOpen, activeFormElementFolder, state.dirtyPreviewFile, state.deletingId, state.editFileRequestId, state.isConfirmAppendAlertOpen, state.needsListElementsIds, multiSelect, formElement?.id, employees, activeFolderId, borrowers, elementsState.list]);


    return (
        <FormElementContext.Provider value={value}>
            {props.children}
            {mergeConfirmDialogOpen && <MergeFormElementConfirmDialog
                open={mergeConfirmDialogOpen}
                onClose={() => setMergeConfirmDialogOpen(false)}
                onConfirm={mergeAcceptCallback.callback}
                formElement={formElement} />}
            {!!deleteDialogOpen && <DeleteQuestionConfirmDialog
                onClose={handleCloseDeleteDialog}
                loading={!!state.deletingId}
                formElement={formElement}
                onConfirm={() => handleConfirmDeleteDialog(deleteDialogOpen)}
                open={!!deleteDialogOpen}
                questionOrAnswer={deleteDialogOpen} />}
            {props.loan?.id && <FormElementFormDialog
                loanEntities={props.loan.loanEntities}
                loanId={props.loan.id} />}
            {!!formElement && <FormElementDialog
                open={formDialogOpen}
                onClose={handleCloseFormElementDialog}
                loanId={props.loan.id}
                formElement={formElement} />}
            <ZipPasswordDialog />
            <ZipDadActionDialog />
            {!!state.templateId && <ResetFileFormElementDialog
                open={!!state.templateId}
                templateId={state.templateId}
                onClose={handleCancelResetFormElement}
                onConfirmReset={handleCopyFormTemplateClick}
                onSelectFilePicker={handleResetFilePickerClick}
            />}
            {isScreenshotDialogOpen && <FormElementCamCapture
                formElement={isScreenshotDialogOpen}
                title={`${createUrlSlug(`${isScreenshotDialogOpen.title}}-${format(new Date(), "yyyyMMdd")}`)}`}
                onClose={handleCloseScreenshotDialog} />}
            {showBoxFiles.length > 0 && <ConfirmationDialog
                open
                onConfirm={() => handleConfirmUploadFilesToShoeBox(showBoxFiles)}
                onClose={() => setShowBoxFiles([])}
                confirmText="Add to Shoebox"
            >
                <div>
                    <Text className="text-center">
                        You are dropping {showBoxFiles.length} items in the loan package.
                        Items not placed on form elements will be added to your shoebox for categorization.
                    </Text>
                </div>
            </ConfirmationDialog>}
            {state.assignElements.length > 0 && <UsersSelectDialog
                open
                onOpenChange={() => onSetAssignElements([])}
                mode={!isLender ? Role.BORROWER : Role.LENDER}
                onSelect={(userIds: string[]) => handleShareFormElement(userIds, state.assignElements[0], true)}
                onUnSelect={(userIds: string[]) => handleUnshareFormElement(userIds, state.assignElements[0])}
                loanRoles={props.loan.loanRoles}
                lenderId={props.loan.lender.id}
                elements={state.assignElements}
                title="Team" />}
        </FormElementContext.Provider>
    );
};

export const useFormElementContext = () => {
    return useContext(FormElementContext);
}
