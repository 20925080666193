import { LoanRoleDto, PackageInfoSharingResponseDto, Role, SharePermissionType } from "src/backend";
import { TeamMemberSelectListAssignedListItem } from "src/components/v2/team-member-select-list/team-member-select-list.types";

export const mapSharedInfoToAssignListItem = (args: { sharedInfo: PackageInfoSharingResponseDto[], loanRoles: Pick<LoanRoleDto, 'role' | 'user'>[], loggedInUserId: string }): TeamMemberSelectListAssignedListItem[] => {
    const loanRoleByUserIdKey = args.loanRoles.reduce((acc, loanRole) => {
        acc[loanRole.user.id] = loanRole.role;
        return acc;
    }, {} as Record<string, Role>);
    return args.sharedInfo
        .filter(sharedInfo => !sharedInfo.permissions.includes(SharePermissionType.LINK))
        .map((sharedInfo: PackageInfoSharingResponseDto) => ({
            user: {
                ...sharedInfo.sharedWithUser,
                isLoggedUser: sharedInfo.sharedWithUser.id === args.loggedInUserId
            },
            role: loanRoleByUserIdKey[sharedInfo.sharedWithUser.id] ?? sharedInfo.sharedWithUser.loggedCompanyRole,
            type: true,
        }))
};